import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import NewsHeader from "../components/NewsDetails/NewsHeader";
import NewsBody from "../components/NewsDetails/NewsBody";
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import { ApiMapsContext } from '../../shared/context/ApiMaps'

const NewsPage = () => {
  const apiMaps = useContext(ApiMapsContext);
  const ArticleId = useParams().ArticleId;

  const [loadedNews, setLoadedNews] = useState();
  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    fetch(
      `${apiMaps.getNews}?id=${ArticleId}`
    )
      .then(res => res.json())
      .then(
        result => {
          setLoadedNews(result);
          console.log(loadedNews);
        },
        error => {}
      );
      trackPageView() // Analytics Tracker
  }, []);

  const handleOnEvent = (ArticleId, loadedNews) => {
    /**
     * category: string;
    action: string;
    name?: string;
    value?: number;
     */
    // Track click on button
    
    trackEvent({ category: 'sample-page', action: 'click-event' , value: ArticleId})
  }

  return (
    <React.Fragment>
    {!loadedNews && (
      <div className="center">
        <LoadingSpinner />
      </div>
    )}
    {loadedNews && (
      <div>
        <div className="modal1-dialog modal-lg">
          <div className="modal1-body">
            <NewsBody onClick={()=>handleOnEvent(ArticleId, loadedNews)} news={loadedNews} ArticleId={ArticleId}/>
          </div>
        </div>
      </div>
    )}
    </React.Fragment>
  );
};

export default NewsPage;
