import React, { useEffect, useState } from "react";
import ArticleCardList from "./ArticleCardList";


const ArticlesByDate = props => {
    const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    const dayNames = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    let month_name = monthNames[props.ddate.getMonth()]
    let day_name = dayNames[props.ddate.getDay()]
    return (
    <>
    <div className="d-flex align-items-center justify-content-between date pt-2" >
        <label className="mb-0">{day_name}</label>
        {/* <span>20 December, 2019</span> */}
        <span>{props.ddate.getDate()} {month_name}, {props.ddate.getFullYear()}</span>
    </div>
    <ArticleCardList articles={props.articles} />
    </>
    );
};

export default ArticlesByDate;
