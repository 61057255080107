import React from 'react';
import { ResponsiveBar } from '@nivo/bar'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.


const StackedBarChart = props => {
    const elm_height = props.height;
    const elm_width = props.width;

    // let data = [
    //     {
    //       "_id": "5ea3dad40959116004156fcb",
    //       "date": "22-04-2020",
    //       "negative": 19,
    //       "neutral": 0,
    //       "positive": 13
    //     },
    //     {
    //       "_id": "5ea3dad40959116004156fcc",
    //       "date": "21-04-2020",
    //       "negative": 18,
    //       "neutral": 0,
    //       "positive": 12
    //     },
    //     {
    //       "_id": "5ea3dad40959116004156fcd",
    //       "date": "20-04-2020",
    //       "negative": 14,
    //       "neutral": 0,
    //       "positive": 3
    //     },
    //     {
    //       "_id": "5ea3dad40959116004156fce",
    //       "date": "19-04-2020",
    //       "negative": 17,
    //       "neutral": 0,
    //       "positive": 21
    //     },
    //     {
    //       "_id": "5ea3dad40959116004156fcf",
    //       "date": "18-04-2020",
    //       "negative": 18,
    //       "neutral": 0,
    //       "positive": 15
    //     },
    //     {
    //       "_id": "5ea3dad40959116004156fd0",
    //       "date": "17-04-2020",
    //       "negative": 25,
    //       "neutral": 2,
    //       "positive": 16
    //     },
    //     {
    //       "_id": "5ea3dad40959116004156fd1",
    //       "date": "16-04-2020",
    //       "negative": 28,
    //       "neutral": 0,
    //       "positive": 30
    //     },
    //     {
    //       "_id": "5ea3dad40959116004156fd2",
    //       "date": "15-04-2020",
    //       "negative": 8,
    //       "neutral": 0,
    //       "positive": 8
    //     }
    //   ];

    // let data = [
    //     {
    //       "country": "AD",
    //       "hot dog": 149,
    //       "hot dogColor": "hsl(306, 70%, 50%)",
    //       "burger": 30,
    //       "burgerColor": "hsl(295, 70%, 50%)",
    //       "sandwich": 142,
    //       "sandwichColor": "hsl(214, 70%, 50%)",
    //       "kebab": 76,
    //       "kebabColor": "hsl(141, 70%, 50%)",
    //       "fries": 29,
    //       "friesColor": "hsl(145, 70%, 50%)",
    //       "donut": 46,
    //       "donutColor": "hsl(336, 70%, 50%)"
    //     },
    //     {
    //       "country": "AE",
    //       "hot dog": 59,
    //       "hot dogColor": "hsl(89, 70%, 50%)",
    //       "burger": 109,
    //       "burgerColor": "hsl(292, 70%, 50%)",
    //       "sandwich": 38,
    //       "sandwichColor": "hsl(276, 70%, 50%)",
    //       "kebab": 116,
    //       "kebabColor": "hsl(54, 70%, 50%)",
    //       "fries": 99,
    //       "friesColor": "hsl(30, 70%, 50%)",
    //       "donut": 174,
    //       "donutColor": "hsl(11, 70%, 50%)"
    //     },
    //     {
    //       "country": "AF",
    //       "hot dog": 140,
    //       "hot dogColor": "hsl(117, 70%, 50%)",
    //       "burger": 155,
    //       "burgerColor": "hsl(183, 70%, 50%)",
    //       "sandwich": 116,
    //       "sandwichColor": "hsl(176, 70%, 50%)",
    //       "kebab": 47,
    //       "kebabColor": "hsl(290, 70%, 50%)",
    //       "fries": 33,
    //       "friesColor": "hsl(162, 70%, 50%)",
    //       "donut": 164,
    //       "donutColor": "hsl(192, 70%, 50%)"
    //     },
    //     {
    //       "country": "AG",
    //       "hot dog": 140,
    //       "hot dogColor": "hsl(19, 70%, 50%)",
    //       "burger": 112,
    //       "burgerColor": "hsl(353, 70%, 50%)",
    //       "sandwich": 100,
    //       "sandwichColor": "hsl(7, 70%, 50%)",
    //       "kebab": 157,
    //       "kebabColor": "hsl(290, 70%, 50%)",
    //       "fries": 92,
    //       "friesColor": "hsl(117, 70%, 50%)",
    //       "donut": 140,
    //       "donutColor": "hsl(194, 70%, 50%)"
    //     },
    //     {
    //       "country": "AI",
    //       "hot dog": 162,
    //       "hot dogColor": "hsl(330, 70%, 50%)",
    //       "burger": 158,
    //       "burgerColor": "hsl(172, 70%, 50%)",
    //       "sandwich": 89,
    //       "sandwichColor": "hsl(316, 70%, 50%)",
    //       "kebab": 132,
    //       "kebabColor": "hsl(343, 70%, 50%)",
    //       "fries": 119,
    //       "friesColor": "hsl(238, 70%, 50%)",
    //       "donut": 30,
    //       "donutColor": "hsl(332, 70%, 50%)"
    //     },
    //     {
    //       "country": "AL",
    //       "hot dog": 83,
    //       "hot dogColor": "hsl(177, 70%, 50%)",
    //       "burger": 103,
    //       "burgerColor": "hsl(320, 70%, 50%)",
    //       "sandwich": 40,
    //       "sandwichColor": "hsl(277, 70%, 50%)",
    //       "kebab": 8,
    //       "kebabColor": "hsl(307, 70%, 50%)",
    //       "fries": 161,
    //       "friesColor": "hsl(171, 70%, 50%)",
    //       "donut": 162,
    //       "donutColor": "hsl(28, 70%, 50%)"
    //     },
    //     {
    //       "country": "AM",
    //       "hot dog": 102,
    //       "hot dogColor": "hsl(308, 70%, 50%)",
    //       "burger": 89,
    //       "burgerColor": "hsl(22, 70%, 50%)",
    //       "sandwich": 112,
    //       "sandwichColor": "hsl(286, 70%, 50%)",
    //       "kebab": 182,
    //       "kebabColor": "hsl(278, 70%, 50%)",
    //       "fries": 152,
    //       "friesColor": "hsl(357, 70%, 50%)",
    //       "donut": 147,
    //       "donutColor": "hsl(278, 70%, 50%)"
    //     }
    //   ];

    return (
    <div style={{"height":"500px","width":"400px"}}>
    <ResponsiveBar
        data={props.sentiment}
        // keys={[ 'hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut' ]}
        keys={[ 'positive', 'neutral', 'negative'  ]}
        // indexBy="country"
        indexBy="date"
        margin={{ top: 50, right: 130, bottom: 70, left: 60 }}
        padding={0.3}
        colors={{ scheme: 'accent' }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        // fill={[
        //     {
        //         match: {
        //             id: 'fries'
        //         },
        //         id: 'dots'
        //     },
        //     {
        //         match: {
        //             id: 'sandwich'
        //         },
        //         id: 'lines'
        //     }
        // ]}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 0,
            tickRotation: -90,
            // legend: 'country',
            // legend: 'date',
            legendPosition: 'middle',
            legendOffset: 50
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'sentiments',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'top-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
    />
    </div>
    );
}

export default StackedBarChart;
