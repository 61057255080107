import React, {useState, useEffect} from 'react';
import SelectedKeywordItem from './SelectedKeywordItem';


const SelectedKeywordList = props => {
    return (
    <div className="keyword">
        {props.keywords.map((keyword,i) => (
            <SelectedKeywordItem key={i} keyword={keyword} filter={props.filter} onKeywordRemove={props.onKeywordRemove} />
        ))}
    </div>
    );
};

export default SelectedKeywordList;
