import React, { useEffect, useState } from "react";
import NewsBodyMain from "./NewsBodyMain";
import NewsBodyRelatedArticle from "./NewsBodyRelatedArticle";

const NewsBody = props => {

  return (
    <>
      <h2 dangerouslySetInnerHTML={{__html: props.news.title}} />
      <NewsBodyMain news={props.news} />
      <NewsBodyRelatedArticle ArticleId={props.news._id} />

    </>
  );
};

export default NewsBody;
