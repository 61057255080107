import React, { useState, useEffect, useContext } from 'react';
// import React from 'react'
import { useMatomo } from '@datapunt/matomo-tracker-react'

import Modal from "react-bootstrap/Modal";
// import {socket} from "./socketIO";

import MainNavigation from './shared/components/Navigation/MainNavigation';

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import './App.css';
import './assets/css/bootstrap.min.css'
import './assets/css/font-awesome.min.css'
import './assets/css/responsive.css'
import './assets/css/style.css'

import NewsStory from './stories/pages/NewsStory';
import MainFooter from './shared/components/Footer/MainFooter';
// import Model from './shared/components/DefinedUIElements/Model';
import NewsPage from './stories/pages/NewsPage'
import NewsModal from './stories/components/NewsDetails/NewsModal'
// import Auth from './user/pages/Auth';

import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';
import LoginModule from './user/login/loginModule';
import { ApiMapsContext } from './shared/context/ApiMaps'


// const io = require('socket.io-client');
// const socket = io('http://192.168.137.1:3000');


function App() {
  const { token, login, logout, userId } = useAuth();
  const { trackPageView, trackEvent } = useMatomo();
  const apiMaps = useContext(ApiMapsContext);
  let routes;

  React.useEffect(() => {
    trackPageView()
  }, [])

  if (token) {
    //// websocket Session start :
    const io = require('socket.io-client');
    const socket = io(`${apiMaps.socketLink}`,{
      // 'access_token':token,
       'transportOptions': {
           'polling': {
              'extraHeaders': {
                 'access_token': token
              }
           }
        }
    });
    // console.log("socket created....")

    routes = (
      <Switch>
        <Route path="/" exact>
          {/* <Users /> */}
          <MainNavigation />
          <NewsStory socket={socket} />
          <MainFooter />
          {/* <Model /> */}

        </Route>

        <Route path="/news/:ArticleId" >
          <MainNavigation />
          <NewsPage />
          <MainFooter />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  }
  else {
    routes = (
      <Switch>
        <Route path="/auth">
            {/* <Auth /> */}
            <LoginModule />
        </Route>

        <Route path="/news/:ArticleId" >
        <MainNavigation />
          <NewsPage />
          <MainFooter />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout
      }}
    >
      <Router>
      {routes}
        {/* <MainNavigation /> */}
        {/* <main></main> */}
      </Router>

    </AuthContext.Provider>
  );
}

export default App;
