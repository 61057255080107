import React from 'react';

import logo from '../../assets/logo.png'
// import vista_logo from '../../assets/Vista.png'
import vista_logo from '../../assets/vista_logo2.png'


const MainFooter = () => {
    return (
    <footer>
		<div className="container">
			<div className="row">
				<div className="col-lg-3">
					<a className="ftr-logo" href="#">
						{/*<img src={logo} alt="" title="" /> */}
            {/*<img src={vista_logo} alt="" title="" />*/}
						<img src={vista_logo} alt="" title="" />
            {/*<sup>AI</sup>*/}
					</a>
					<p className="purpal pt-4 mt-1">Vista is a predictive analytics platform that connects the dots between the news, social media and the market with the power of Artificial Intelligence</p>
					<ul className="logo-icon d-flex align-items-center">
						<li><a href="javascript:void(0)"><img src="images/apple.png" align="Apple" title="" /></a></li>
						<li><a href="javascript:void(0)"><img src="images/playstore.png" align="Playstore" title="" /></a></li>
					</ul>
				</div>
				<div className="col-lg-2">
					<ul>
						<li><a href="javascript:void(0)">Hews</a></li>
						<li><a href="javascript:void(0)">Finance</a></li>
						<li><a href="javascript:void(0)">Energy</a></li>
					</ul>
				</div>
				<div className="col-lg-2">
					<ul>
						<li><a href="javascript:void(0)">Market</a></li>
						<li><a href="javascript:void(0)">TBD</a></li>
					</ul>
				</div>
				<div className="col-lg-2">
					<ul>
						<li><a href="javascript:void(0)">How It Works</a></li>
						<li><a href="javascript:void(0)">About Us</a></li>
						<li><a href="javascript:void(0)">Purchase Enquiry</a></li>
						<li><a href="javascript:void(0)">FAQs</a></li>
						<li><a href="javascript:void(0)">Customer Care</a></li>
						<li><a href="javascript:void(0)">Careers</a></li>
					</ul>
				</div>
				<div className="col-lg-3">
					<p className="pb-3">Vista  is a product of Vista Intelligence Pvt. Ltd.,registered at 3rd Floor, Flat-3A&B, 18A,Tara Shankar Sarani, Tala, Kolkata WB 700037 IN</p>
					<p>Phone:&nbsp;<a href="tel:+912266806010">+91 22 6680 6010</a></p>
					<p>Email:&nbsp;<a href="mailto:contact@vistaintelligence.com">contact@vistaintelligence.com</a></p>
				</div>
			</div>
			<div className="copyright">
				<p>Copyright ©2019 Vista Intelligence Private Limited. All Rights Reserved.</p>
			</div>
		</div>
	</footer>
    );
}

export default MainFooter;
