import React, { useEffect, useState, useContext } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { CardDeck, Card, CardColumns, Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import TopNews from '../components/TopNews/TopNews';
import SmartStories from '../components/SmartStories/SmartStories';

import { useHttpClient } from '../../shared/hooks/http-hooks'
import NewsModal from '../components/NewsDetails/NewsModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import { AuthContext } from '../../shared/context/auth-context';
import { ApiMapsContext } from '../../shared/context/ApiMaps'
import NiftyChart from '../../shared/components/DefinedUIElements/NiftyChart';
import Linkify from 'react-linkify';
import { useHistory } from "react-router-dom";



function useStickyState(defaultValue, key) {

    const [value, setValue] = React.useState(() => {
        const stickyValue = window.localStorage.getItem(key);
        return stickyValue !== null
            ? JSON.parse(stickyValue)
            : defaultValue;
    });
    React.useEffect(() => {

        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
}
let radios = [
    { name: 'Trending', value: 'Trending' }
  ];

let graphDateValues = [
    {"name": "Today", "value": 0},
    {"name": "Prev Week", "value": 7},
    {"name": "Fortnightly", "value": 14},
    {"name": "Monthly", "value": 28},
]

const NewsStory = props => {
    const { trackPageView, trackEvent } = useMatomo()
    const socket = props.socket;
    const apiMaps = useContext(ApiMapsContext);
    const auth = useContext(AuthContext);
    const userId = auth.userId
    const access_token = auth.token;

    const [modalTimer, setModalTimer] = useState();
    let modalDwellTime;

    const [show, setShow] = useState(false);
    const [newsItem, setNewsItem] = useState();
    const [wordCloudCategories, setWordCloudCategories] = useState();
    const [categoryNews, setcategoryNews] = useState();
    const [radioValue, setRadioValue] = useState('Trending');
    const [trendNews, setTrendNews] = useState();
    const [loadedNifty, setLoadedNifty] = useState();
    const [graphDate, setGraphDate] = useState(0);

    const [loadedNews, setLoadedNews] = useState();
    const [bookmarkNews, setBookmarkNews] = useStickyState([], "bookmarkNews");
    // const [bookmarkNews, setBookmarkNews] = useState([]);
    const [loadedFeeds, setLoadedFeeds] = useState();

    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const history = useHistory();

    // sending user behavior socket logs
    const userBehaviorHandler = (behaviorData) => {
        let recommended_news = []
        let trending_news = []
        for (let i = 0; i < loadedNews.length; i++) {
            recommended_news.push(loadedNews[i]._id);
        }
        for (let i = 0; i < loadedFeeds.length; i++) {
            trending_news.push(loadedFeeds[i]._id);
        }

        // sending filter logs
        if (behaviorData["action_name"] == "insert_filter") {
            let fkeywords = behaviorData["keywords"] || []
            let fcategories = behaviorData["categories"] || []
            let fcompanies = behaviorData["companies"] || []

            let req2 = {
                "user_id": userId,
                "filter": {
                    "keywords": fkeywords,
                    "categories": fcategories,
                    "companies": fcompanies
                }
            }
            // console.log(req2)
            socket.emit('insert_filter', JSON.stringify(req2))
        }
        // sending filter logs
        else if (behaviorData["action_name"] == "delete_filter") {
            let fkeywords = behaviorData["keywords"] || []
            let fcategories = behaviorData["categories"] || []
            let fcompanies = behaviorData["companies"] || []

            let req2 = {
                "user_id": userId,
                "filter": {
                    "keywords": fkeywords,
                    "categories": fcategories,
                    "companies": fcompanies
                }
            }
            // console.log(req2)
            socket.emit('delete_filter', JSON.stringify(req2))
        }
        // sending behavior logs
        else {
            let aDwellTime = behaviorData["dwell_time"] || ""
            let req2 = {
                "user_id": userId,
                "actions": [{
                    // total actions : like|dislike|share|bookmark|click|close
                    "action_name": behaviorData["action_name"],
                    "article_id": behaviorData["article_id"],
                    "action_timestamp": "",
                    "dwell_time": aDwellTime, // in seconds
                    "displayed_items": {
                        "trending_news": trending_news,
                        "recommended_news": recommended_news
                    }
                }]
            }

            if (behaviorData["action_name"] == "like" || behaviorData["action_name"] == "dislike") {
                req2["actions"][0]["displayed_items"]["recommended_news"] = []
            }
            else {
                req2["actions"][0]["displayed_items"]["trending_news"] = []
            }
            // console.log("sending behavior request")
            // console.log(req2)
            // console.log(socket)
            socket.emit('update_action', JSON.stringify(req2))
        }

        //
        // let req2 = {
        //     "user_id":userId,
        //     "session_start_timestamp": "",
        //     "session_end_timestamp":"",
        //     "session_id":"",
        //     // "filter":{
        //     //     "keywords":[],
        //     //     "categories":[],
        //     //     "companies":[]
        //     // },
        //     "actions":[{
        //         // total actions : like|dislike|share|bookmark|click|close
        //         "action_name":behaviorData["action_name"],
        //         "article_id":behaviorData["article_id"],
        //         "action_timestamp":"",
        //         "dwell_time":"", // in seconds
        //         "displayed_items":{
        //             "trending_news":trending_news,
        //             "recommended_news":recommended_news
        //         }
        //     }]
        // }
        //
        // if(behaviorData["action_name"] == "like" || behaviorData["action_name"] == "dislike"){
        //     req2["actions"][0]["displayed_items"]["recommended_news"] = []
        // }
        // else{
        //     req2["actions"][0]["displayed_items"]["trending_news"] = []
        // }
        // console.log("sending behavior request")
        // console.log(req2)
        // socket.emit('update_action', JSON.stringify(req2))
    }

    const modelShowHandler = (item) => {
        setNewsItem(item);
        setShow(true);

        setModalTimer(new Date().getTime());
        /**
     * category: string;
    action: string;
    name?: string;
    value?: number;
     */
        // Track click on button    
        console.log(item)
        trackEvent({ category: item.category, action: 'click', name: item.keywords.toString() })
        // console.log("modal time:"+modalTimer)
    }
    const modelCloseHandler = (e) => {
        // console.log(newsItem)
        setShow(false);

        modalDwellTime = (new Date().getTime() - modalTimer) / 1000;
        // console.log("dwell time :"+modalDwellTime)

        //// sending dwell time data to socket
        let shareData = {
            "action_name": "click",
            "dwell_time": modalDwellTime,
            "article_id": newsItem._id
        }
        // console.log("dwell_time data :"+shareData)
        userBehaviorHandler(shareData)
    }

    const showMoreHandler = (moreNews) => {
        trackEvent({ category: "moreNews", action: 'click', name: [...moreNews].toString(), value: [...moreNews] })
        setLoadedNews(loadedNews => [...loadedNews, ...moreNews])
    }

    const addBookmarkHandler = (item) => {
        if (!bookmarkNews.includes(item)) {
            setBookmarkNews(bookmarkNews => [...bookmarkNews, item])

            //// sending boomark data to socket
            let shareData = {
                "action_name": "bookmark",
                "article_id": item._id
            }
            // console.log("Bookmark data :"+shareData)
            userBehaviorHandler(shareData)
        }
    }

    const removeBookmarkHandler = (item) => {
        var index = bookmarkNews.indexOf(item);
        if (index !== -1) {
            bookmarkNews.splice(index, 1);
            // console.log(bookmarkNews)
            setBookmarkNews(bookmarkNews => [...bookmarkNews])

            //// sending boomark data to socket
            let shareData = {
                "action_name": "bookmark_remove",
                "article_id": item._id
            }
            // console.log("Bookmark data :"+shareData)
            // userBehaviorHandler(shareData)

        }
    }

    const fetchChartData = (endpoint) => {
        fetch(endpoint,
        {
            method: "GET",
            dataType: "JSON",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        })
        .then(res => res.json())
        .then(
            result => {
                let temp_date = [
                    {
                        "id":"Market Sentiment",
                        "data":result["twitter_sentiment"]
                    }
                ]
                setLoadedNifty(temp_date);
            },
            error => {}
        );
    }

    const chartActionHandler = (e) => {
        //buttonNameRef.current.children[0].innerText = e.target.name;
        let temp_type = e
        setGraphDate(e)

        // console.log(temp_type);
        // setCurrentDays(currentDays => temp_type);
        // console.log(currentDays);

        fetchChartData(`${apiMaps.niftyGraph}?category=NIFTY 50&days=${temp_type}`);
    };

    useEffect(() => {
        fetchChartData(`${apiMaps.niftyGraph}?category=NIFTY 50&days=0`);
    }, []);
    // useEffect(() => {
    //     const fetchNews = async () => {
    //       try {
    //         const responseData = await sendRequest(
    //           `${apiMaps.latestNews}?access_token=${access_token}`
    //         );
    //         setLoadedNews(responseData.recommended_articles);
    //       } catch (err) {
    //           console.log(err);
    //       }
    //     };
    //     fetchNews();
    //   }, [sendRequest]);

    useEffect(() => {
        trackPageView();
        fetch(`${apiMaps.latestNews}?access_token=${access_token}`)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    setLoadedNews(result.recommended_articles);
                },
                (error) => {
                }
            )
    }, []);

    useEffect(() => {
        fetch(`${apiMaps.feedSense}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setLoadedFeeds(result.tweets);
                },
                (error) => {
                }
            )
    }, []);

    // interval tweets
    useEffect(() => {
        setInterval(() => {
            fetch(`${apiMaps.feedSense}`)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("tweets loaded")
                        setLoadedFeeds(result.tweets);
                    },
                    (error) => {
                    }
                )
        }, 60 * 1000);

    }, []);

    useEffect(() => {
        // console.log("receving on socket.....")
        socket.on("receive_news", (news) => {
            //   console.log(news)
            // console.log("request socket on")
            setLoadedNews(loadedNews => [...news, ...loadedNews]);


        });
    }, [socket])

    useEffect(() => {
        // console.log("news Item changed")
    }, [newsItem]);

    useEffect(() => {
        if (wordCloudCategories != undefined) {
            return Promise.all(wordCloudCategories.map(category => fetch(`${apiMaps.getCategoryNews}?category=${category.text}&skip=0&limit=4`))).then(responses =>
                Promise.all(responses.map(res => res.json()))
            ).then(result => {

                //catMapData.push({"category": category.text, result})
                // console.log(catMapData)
                setcategoryNews(result)

            }),
                (error) => {
                    console.log(error)
                }
        }

    }, [wordCloudCategories])

    useEffect(() => {
        fetch(`${apiMaps.wordCloud}?type=category`)
            .then(res => res.json())
            .then(
                (result) => {
                    let filter = result.filter;
                    let obj = []
                    let temp_i = 1
                    for (let key in filter) {
                        obj.push({
                            "id": temp_i,
                            "text": key,
                            "value": filter[key]
                        })
                        temp_i++;
                    }
                    obj = obj.sort((a, b) => b.value - a.value)
                    radios = []
                    radios.push({ name: 'Trending', value: 'Trending' })
                    radios.push({"name":obj[0].text, "value": obj[0].text})
                    radios.push({"name":obj[1].text, "value": obj[1].text})
                    setWordCloudCategories(obj);
                },
                (error) => {
                }
            )
    }, []);

    const fetchCategoryNews = (value) =>{
        setRadioValue(value);
        fetch(`${apiMaps.getCategoryNews}?category=${value}&skip=0&limit=5`)
        .then(res => res.json())
                .then(
                    (result) => {
                        setTrendNews(result);
                    },
                    (error) => {
                    }
                )

    }

    const openArticle = (el) =>{
        history.push(`/news/${el._id}`) 
        // return <Link to="/news" params={el._id} target="_blank"></Link>
    }

    return (
        <React.Fragment>
            {(!loadedNews || !loadedFeeds) && (
                <div className="center">
                    <LoadingSpinner />
                </div>
            )}
            {loadedNews && loadedFeeds && (
                <>
                    <div className="container">
                        <div className='row'>
                            <div className='col-md-7'><Card variant="bottom" className="p-1 mainCard" onClick={()=>openArticle(loadedNews[0])}>
                                <Card.Img src={loadedNews[0].image_link} className="mainImg" />
                                <Card.ImgOverlay>
                                    <Card.Title style = {{"position": "absolute", "bottom": "20vh", "fontSize": "2em!important"}} className="cardTextBG">{loadedNews[0].title}</Card.Title>
                                    <Card.Text style = {{"position": "absolute", "bottom": "12vh"}}>
                                        {loadedNews[0].keywords.map((el, i) => {
                                            if (i < 5)
                                                return <span className="badge rounded-pill bg-dark" key={i}>{el}</span>
                                        })}

                                        {/* {loadedNews[0].summary} */}
                                    </Card.Text>
                                </Card.ImgOverlay>
                                <Card.Footer className="mainFooter">
                                    <div><span>
                                        <img className="publisherLogo" src={loadedNews[0].logo} /></span> &nbsp;
                                        <span className="sentiment">

                                            {(() => {
                                                if (loadedNews[0].sentiment == 0)
                                                    return <i className="fa fa-circle"></i>
                                                else if (loadedNews[0].sentiment > 0)
                                                    return <i className="fa fa-plus-circle"></i>
                                                else
                                                    return <i className="fa fa-minus-circle"></i>
                                            }
                                            )()}

                                        </span></div>
                                </Card.Footer>
                            </Card></div>
                            <div className='col-md-5'>
                                
                            <ButtonGroup toggle>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    className="trendingButtonGroup"
                                    type="radio"
                                    variant="primary"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={(e) => fetchCategoryNews(e.currentTarget.value)}
                                >
                                    {radio.name}
                                </ToggleButton>
                                ))}
                            </ButtonGroup>

                                <ul className="list-group">
                                    {radioValue == 'Trending' && loadedNews.map((el,i)=>{
                                        if(i<5){
                                        return <div className="card" style={{ "padding": "0","maxHeight":"12vh","marginTop":"0.15rem" , "border": "none", "borderBottom":"1px dashed black"}} onClick={()=>openArticle(el)}>
                                            <span className="sentiment">

                                                {(() => {
                                                    if (el.sentiment == 0)
                                                        return <i className="fa fa-circle"></i>
                                                    else if (el.sentiment > 0)
                                                        return <i className="fa fa-plus-circle"></i>
                                                    else
                                                        return <i className="fa fa-minus-circle"></i>
                                                }
                                                )()}

                                            </span>
                                        <div className="row g-0">
                                            <div className="col-md-3">
                                                <img style={{ "height": "100%","objectFit":"cover" ,"maxWidth":"8vw","maxHeight":"11vh"}} src={el.image_link} alt="..." />
                                            </div>
                                            <div className="col-md-9">
                                                <div className="card-body p-0">
                                                    <div style={{"fontSize": ".75em"}}><span style={{"font-weight": "900", "textTransform":"uppercase","color":"#263790"}}>{el.category}</span> / <span>{new Date(el.datePublished).toDateString()}</span></div>
                                                    <p className="card-text p-0" style={{"marginRight": "2rem"}}>{el.title}</p>
                                                    <img src={el.logo} style={{"height": "15px"}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }})}
                                    {radioValue != 'Trending' && trendNews && trendNews.map((el,i)=>{
                                        if(i<5){
                                        return <div className="card" style={{ "padding": "0rem","maxHeight":"12vh","marginTop":"0.15rem" , "border": "none", "borderBottom":"1px dashed black"}} onClick={()=>openArticle(el)}>
                                            <span className="sentiment">

                                                {(() => {
                                                    if (el.sentiment == 0)
                                                        return <i className="fa fa-circle"></i>
                                                    else if (el.sentiment > 0)
                                                        return <i className="fa fa-plus-circle"></i>
                                                    else
                                                        return <i className="fa fa-minus-circle"></i>
                                                }
                                                )()}

                                            </span>
                                        <div className="row g-0">
                                            <div className="col-md-3">
                                                <img style={{ "height": "100%","objectFit":"cover" ,"maxWidth":"8vw","maxHeight":"11vh"}} src={el.image_link} alt="..." />
                                            </div>
                                            <div className="col-md-9">
                                                <div className="card-body p-0">
                                                    <div style={{"fontSize": ".75em"}}><span style={{"font-weight": "900", "textTransform":"uppercase","color":"#263790"}}>{el.category}</span> / <span>{new Date(el.datePublished).toDateString()}</span></div>
                                                    <p className="card-text p-0" style={{"marginRight": "2rem"}}>{el.title}</p>
                                                    <img src={el.logo} style={{"height": "15px"}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }})}
                                </ul>
                            </div>
                        </div>
                        
                        
                            
                            <div className = "row g-0">
                            <div className="col-md-8" >
                                <div className="row" >
                                    <div className="card" style={{"width": "100%", "height": "100%"}}
                                    >
                                        <h5>Nifty Sentiments</h5>
                                        <ButtonGroup toggle>
                                            {graphDateValues.map((radio, idx) => (
                                            <ToggleButton
                                                className = "sentimentGraph"
                                                
                                                key={idx}
                                                type="radio"
                                                variant="light"
                                                name="radio"
                                                value={radio.value}
                                                checked={graphDate === radio.value}
                                                onChange={(e) => chartActionHandler(e.currentTarget.value)}
                                            >
                                                {radio.name}
                                            </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                        {loadedNifty && 
                                        <NiftyChart niftyData={loadedNifty} height="40vh" width="100%" />
                                        }
                                        
                                    </div>
                                </div>
                                {wordCloudCategories && wordCloudCategories.map((item, index) => {
                                if(!radios.map((x)=>x.name).includes(item.text) && index <5) {
                                    return <div>
                                <div className="newsHeader">
                                    {item.text}
                                </div>
                                <CardDeck> 
                                    {categoryNews && categoryNews.filter((el) => el[0].category == item.text )[0]
                                        .map((el, i) => 
                                        {
                                            if(i<3){
                                                return <Card className = "p-0" onClick={()=>openArticle(el)}>
                                                <Card.Img variant="top" src={el.image_link} />
                                                <Card.Body className="p-0">
                                                    <Card.Title className="subSectionHeading">{el.title}</Card.Title>
                                                    <Card.Text>
                                                        {el.keywords.map((el1, i) => {
                                                            if (i < 5)
                                                                return <span className="badge bg-light text-dark custom-border" key={i}>{el1}</span>
                                                        })}</Card.Text>
                                                </Card.Body>
                                                <Card.Footer className="mainFooter">
                                                    <div><span>
                                                        <img className="publisherLogo" src={el.logo} /></span>
                                                        <span className="sentiment">

                                                            {(() => {
                                                                if (el.sentiment == 0)
                                                                    return <i className="fa fa-circle"></i>
                                                                else if (el.sentiment > 0)
                                                                    return <i className="fa fa-plus-circle"></i>
                                                                else
                                                                    return <i className="fa fa-minus-circle"></i>
                                                            }
                                                            )()}

                                                        </span></div>
                                                </Card.Footer>
                                            </Card>
                                            }
                                            
                                        })}
                                </CardDeck>
                            </div>
                                }
                                
                            
                            })}
                            </div>
                            <div className="col-md-4" >
                                    <div className="card" style={{"width": "100%", "height": "100%"}}>
                                        <h5>FeedSense</h5>
                                        <ul className="list-group">
                                                {loadedFeeds && loadedFeeds.map((el,i)=>{
                                                    return <div className = "card p-0">
                                                        <span className="sentiment">

                                                            {(() => {
                                                                if (el.sentiment == 0)
                                                                    return <i className="fa fa-circle"></i>
                                                                else if (el.sentiment > 0)
                                                                    return <i className="fa fa-plus-circle"></i>
                                                                else
                                                                    return <i className="fa fa-minus-circle"></i>
                                                            }
                                                            )()}

                                                        </span>
                                                        <div className="row g-0" style={{ "borderBottom": "1px dashed gray" }}>
                                                            <div className="col-md-3">
                                                                <img style={{ "height": "100%", "objectFit": "cover", "maxWidth": "8vw", "maxHeight": "11vh" }} src={el.user.display_url} alt="..." />
                                                                <span style={{"color":"#1DA1F2", "position": "absolute", "top": "-5px","left":"20px","fontSize": "2rem"}}><i className="fa fa-twitter"></i></span>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="card-body p-0">
                                                                    <div style={{ "fontSize": ".75em" }}><span style={{ "font-weight": "900", "textTransform": "uppercase", "color": "#263790" }}><a target="blank" href={el.user.user_url}>{el.user.name}</a></span> / <span>{new Date(el.created_at).toDateString()}</span></div>
                                                                    <p className="card-text p-0" style={{ "marginRight": "2rem", "fontSize": ".75em" }}>
                                                                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => ( <a target="blank" href={decoratedHref} key={key} style={{"color":"#1da1f2", "textDecoration":"underline"}}> {decoratedText} </a> )}>
                                                                        {el.full_text}
                                                                        </Linkify>
                                                                        </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                        </ul>
                                    </div>
                                </div>
                                {wordCloudCategories.map((item, index) => {
                                if(index >=5) {
                                    return <div>
                                <div className="newsHeader">
                                    {item.text}
                                </div>
                                <CardDeck> 
                                    {categoryNews && categoryNews.filter((el) => el[0].category == item.text )[0]
                                        .map((el, i) => 
                                        {
                                            if(i<4){
                                                return <Card className = "p-0" onClick={()=>openArticle(el)}>
                                                <Card.Img variant="top" src={el.image_link} />
                                                <Card.Body className="p-0">
                                                    <Card.Title className="subSectionHeading">{el.title}</Card.Title>
                                                    <Card.Text>
                                                        {el.keywords.map((el1, i) => {
                                                            if (i < 5)
                                                                return <span className="badge bg-light text-dark custom-border" key={i}>{el1}</span>
                                                        })}</Card.Text>
                                                </Card.Body>
                                                <Card.Footer className="mainFooter">
                                                    <div><span>
                                                        <img className="publisherLogo" src={el.logo} /></span>
                                                        <span className="sentiment">

                                                            {(() => {
                                                                if (el.sentiment == 0)
                                                                    return <i className="fa fa-circle"></i>
                                                                else if (el.sentiment > 0)
                                                                    return <i className="fa fa-plus-circle"></i>
                                                                else
                                                                    return <i className="fa fa-minus-circle"></i>
                                                            }
                                                            )()}

                                                        </span></div>
                                                </Card.Footer>
                                            </Card>
                                            }
                                            
                                        })}
                                </CardDeck>
                            </div>
                                }
                                
                            
                            })}
                            </div>
                    </div>
                    {/* <TopNews items={loadedNews.slice(0,3)} OnModelShow={modelShowHandler} />
              <SmartStories news={loadedNews.slice(3)} feeds={loadedFeeds} OnModelShow={modelShowHandler}
                  OnLoadMore={showMoreHandler} bookmarkNews={bookmarkNews} OnRemoveBookmark={removeBookmarkHandler}
                  onUserBehavior={userBehaviorHandler} />
              <NewsModal show={show} handleClose={modelCloseHandler} news={newsItem} OnPinStory={addBookmarkHandler} /> */}
                </>
            )}
        </React.Fragment>

        // <React.Fragment>
        // {isLoading && (
        //     <div className="center">
        //         <p>Loading...</p>
        //     {/* <LoadingSpinner /> */}
        //     </div>
        // )}
        // {!isLoading && loadedNews && (
        //     <React.Fragment>
        //         <TopNews />
        //         <SmartStories />
        //     </React.Fragment>
        // )}

        // </React.Fragment>
    );
};

export default NewsStory;
