import React from 'react';
import StoryKeywordItem from './StoryKeywordItem';
import StoryKeywordMoreItems from './StoryKeywordMoreItems';

const StoryKeywordList = props => {
    let kw_lst1 = props.kw_lst.slice(0,4);
    let kw_lst2 = props.kw_lst.slice(4);

    let storyType = props.storyType;

    return (
    <React.Fragment>
        {kw_lst1.map( (name,i) => (
            <StoryKeywordItem key={i} name={name} />
        ))}
        {kw_lst2.length > 0 && props.more &&
          kw_lst2.map( (name,i) => (
            <StoryKeywordItem key={i} name={name} />
            ))
        }
        {kw_lst2.length > 0 && !props.more && storyType=="top3" &&
        <React.Fragment>
            {/* <label className="badge ml-1 mt-2 mb-0"><span className="light-black">...</span></label> */}
            <label className="badge ml-1 mt-2 mb-0"><StoryKeywordMoreItems className="light-black" keywords={kw_lst2} /></label>
        </React.Fragment>
        }
        {kw_lst2.length > 0 && !props.more && storyType=="smartStory" &&
        <React.Fragment>
            <label className="badge ml-1 mt-2 mb-0"><span className="light-black">...</span></label>
        </React.Fragment>
        }

        {/* {kw_lst2.length > 0 && !props.more && storyType=="smartStory" &&
          kw_lst2.map( (name,i) => (
            <StoryKeywordItem key={i} name={name} />
            ))
        } */}

    </React.Fragment>
    );
}

export default StoryKeywordList;
