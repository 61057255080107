import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import AppTest from './AppTest';
import * as serviceWorker from './serviceWorker';


import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
 
const instance = createInstance({
  urlBase: 'http://128.199.16.114/',
  siteId: 1,
//   userId: 'UID76903202', // optional, default value: `undefined`.
  trackerUrl: 'http://128.199.16.114/matomo.php', // optional, default value: `${urlBase}matomo.php`
  srcUrl: 'http://128.199.16.114/matomo.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: false,
    setSecureCookie: true,
    setRequestMethod: 'POST'
  }
})
 
ReactDOM.render(
  <MatomoProvider value={instance}>
    <App />
  </MatomoProvider>, document.getElementById('root')
)


// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
