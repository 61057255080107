import React, { useState, useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown'

import logo from '../../assets/logo.png'
// import vista_logo from '../../assets/Vista.png'
import vista_logo from '../../assets/vista_logo2.png'
import profile_img from '../../assets/profile2.png'

import './MainNavigation.css';

import { AuthContext } from '../../context/auth-context'
import { ApiMapsContext } from '../../context/ApiMaps'

const MainNavigation = props => {
		const auth = useContext(AuthContext);
		const apiMaps = useContext(ApiMapsContext);

		const logoutHandler = () => {
				// console.log(`${apiMaps.logout}?access_token=${auth.token}`)
				fetch(`${apiMaps.logout}?access_token=${auth.token}`)
				.then(
						(result) => {
								auth.logout();
						},
						(error) => {
						}
				)
		}

    return (
    <header>
		{/* <nav className="navbar navbar-expand-lg navbar-default">
		  <a className="navbar-brand" href="#">
				<img src={vista_logo} alt="" title="" />
				<sup>AI</sup>
			</a>
		  <button className="navbar-toggler navbar-toggler-right collapsed" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
            <span> </span>
            <span> </span>
            <span> </span>
          </button>

		  <div className="collapse navbar-collapse" id="collapsingNavbar">
		    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
		      <li className="nav-item">
		        <a className="nav-link active" href="#">News</a>
		      </li>
		      <li className="nav-item">
		        <a className="nav-link" href="#">Market</a>
		      </li>
		    </ul>
			<Dropdown>
				<Dropdown.Toggle variant="transparent" id="dropdown-basic">
					<label className="mb-0 profile">
					<i className="fa fa-user fa-3x" aria-hidden="true"></i>
						
					</label>
				</Dropdown.Toggle>

				<Dropdown.Menu>
					<Dropdown.Item href="#" onClick={logoutHandler} >Log Out</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>

		  </div>
		</nav> */}

<nav className="navbar navbar-expand-lg navbar-light bg-light">
<div className="container-fluid">
    <a className="navbar-brand" href="#">
      <img src={vista_logo} alt="" width="100"  className="d-inline-block align-text-center"/>
      &nbsp;&nbsp;Vista Intelligence
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarText">
      <span className="navbar-text justify-content-end">
       	Kolkata | {new Date().toDateString()} | <i className="fa fa-facebook"></i> | <i className="fa fa-twitter"></i> | <i className="fa fa-linkedin"></i>  
      </span>
    </div>
  </div>
  <Dropdown>
				<Dropdown.Toggle variant="transparent" id="dropdown-basic">
					<label className="mb-0 profile">
					<i className="fa fa-user fa-3x" style={{"color":"gray"}} aria-hidden="true"></i>
						
					</label>
				</Dropdown.Toggle>

				<Dropdown.Menu>
					<Dropdown.Item href="#" onClick={logoutHandler} >Log Out</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
</nav>
	</header>

    );
}

export default MainNavigation;
