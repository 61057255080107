import React, { useState, useContext } from 'react';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
// import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ImageUpload from '../../shared/components/FormElements/ImageUpload';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE
} from '../../shared/util/validators';
// import { useForm } from '../../shared/hooks/form-hook';
import { useForm } from "react-hook-form";

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { ApiMapsContext } from '../../shared/context/ApiMaps';

import '../../assets/css/login_main.css'
import '../../assets/css/login_util.css'

import login_img from '../../assets/images/img-01.png'

const io = require('socket.io-client');


const LoginModule = () => {
  const auth = useContext(AuthContext);
  const apiMaps = useContext(ApiMapsContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  // const [formState, inputHandler, setFormData] = useForm(
  //   {
  //     email: {
  //       value: '',
  //       isValid: false
  //     },
  //     password: {
  //       value: '',
  //       isValid: false
  //     }
  //   },
  //   false
  // );

  const { register, handleSubmit } = useForm();

  const switchModeHandler = () => {
    setIsLoginMode(prevMode => !prevMode);
  };

  const authSubmitHandler = async data => {
    // event.preventDefault();

    if (isLoginMode) {
      try {
        const responseData = await sendRequest(
          apiMaps.login,
          // 'http://localhost:3000/requesthandler/v1/user/login',
          'POST',
          JSON.stringify({
            user_id: data.email,
            // email: data.email,
            // user_id: formState.inputs.email.value,
            password: data.password
          }),
          {
            'Content-Type': 'application/json'
          }
        );
        // const socket = io(apiMaps.socketLink);
        // console.log("login socket ....................:")
        // console.log(socket);
        // console.log("login socket ....................:")

        auth.login(responseData.user_id, responseData.access_token);

        // auth.login(responseData.userId, responseData.token);
      } catch (err) {}
    }
    else {
      try {
        // const formData = new FormData();
        // formData.append('email', formState.inputs.email.value);
        // formData.append('name', formState.inputs.name.value);
        // formData.append('password', formState.inputs.password.value);
        // formData.append('image', formState.inputs.image.value);
        const responseData = await sendRequest(
          // 'http://localhost:5000/api/users/signup',
          apiMaps.signup,
          'POST',
          JSON.stringify({
            // _id: formState.inputs.email.value,
            name: data.name,
            user_id: data.email,
            password: data.password,
            // ipaddress:"10.100.59.64",
            // geolocation:{
            //          "region":"AP",
            //          "state":"Gujarat",
            //          "city":"Gandhinagar",
            //          "country":"India"
            //         }
          }),
          {
            'Content-Type': 'application/json'
          }
        );
        // const socket = io(apiMaps.socketLink);

        auth.login(responseData.user_id, responseData.access_token);
      } catch (err) {}
    }
  };

  return (

    <div className="limiter">
		<div className="container-login100">
			<div className="wrap-login100">
				<div className="login100-pic js-tilt" data-tilt>
					<img src={login_img} alt="IMG" />
				</div>

				<form className="login100-form validate-form" onSubmit={handleSubmit(authSubmitHandler)}>
					<span className="login100-form-title">
            {isLoginMode ? 'Member Login' : 'Member Registration'}
					</span>

          {!isLoginMode && (
            <div className="wrap-input100" data-validate = "Valid Name is required: ex@abc.xyz">
              <input className="input100" id="name" type="text" name="name" placeholder="Name" ref={register} />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </span>
            </div>
          )}

					<div className="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
						<input className="input100" type="email" name="email" placeholder="Email" ref={register} />
						<span className="focus-input100"></span>
						<span className="symbol-input100">
							<i className="fa fa-envelope" aria-hidden="true"></i>
						</span>
					</div>

					<div className="wrap-input100 validate-input" data-validate = "Password is required">
						<input className="input100" type="password" name="password" placeholder="Password" ref={register} />
						<span className="focus-input100"></span>
						<span className="symbol-input100">
							<i className="fa fa-lock" aria-hidden="true"></i>
						</span>
					</div>

					<div className="container-login100-form-btn">
            {/* <Button className="login100-form-btn" type="submit" disabled={!formState.isValid}>
              {isLoginMode ? 'Login' : 'Signup'}
            </Button> */}

						<button className="login100-form-btn" type="submit">
              {isLoginMode ? 'Login' : 'Signup'}
						</button>
					</div>

					<div className="text-center p-t-136">
						<a className="txt2" href="#" onClick={switchModeHandler}>
              SWITCH TO {isLoginMode ? 'Create your Account' : 'Login'}
							<i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
						</a>
					</div>
				</form>
			</div>
		</div>
	</div>

  );
};

export default LoginModule;
