import { createContext } from 'react';

//const BASE_API = "http://142.93.211.1:3000";
//const SOCKET_LINK = "http://142.93.211.1:3000";
//const REACT_URL = "http://142.93.211.1"

//http://142.93.213.120

const BASE_API = "http://142.93.213.120:3000";
const SOCKET_LINK = "http://142.93.213.120:3000";
const REACT_URL = "http://142.93.213.120"

export const ApiMapsContext = createContext({
    socketLink: `${SOCKET_LINK}`,
    reactUrl: `${REACT_URL}`,

    shareLink: `${REACT_URL}/news/`,

    // endpoints
    login: `${BASE_API}/requesthandler/v1/user/login`,
    logout: `${BASE_API}/requesthandler/v1/user/logout`,
    signup: `${BASE_API}/requesthandler/v1/user/newuser`,
    sentimentGraph: `${BASE_API}/requesthandler/v1/news/sentiment_analytics`,
    niftyGraph: `${BASE_API}/requesthandler/v1/niftysentiment/`,
    relatedArticles: `${BASE_API}/requesthandler/v1/news/related_news/`,
    wordCloud: `${BASE_API}/requesthandler/v1/getwordcloud`,
    getMoreNews: `${BASE_API}/requesthandler/v1/getmorenews`,
    getNews: `${BASE_API}/requesthandler/v1/news/id/`,
    latestNews: `${BASE_API}/requesthandler/v1/news`,
    feedSense: `${BASE_API}/requesthandler/v1/feedsense`,
    selectedFilter: `${BASE_API}/requesthandler/v1/getselectedterms`,
    getCategoryNews: `${BASE_API}/requesthandler/v1/news/getnews`

});
