import React, { useState, useEffect } from "react";

const SelectedKeywordItem = props => {

    const removeKeywordHandler = () => {
        props.onKeywordRemove(
            props.filter,
            props.keyword
        )
    }

    return (
    <label className="badge bg-orange">
        {props.keyword}
        <a >
        <i className="fa fa-times" aria-hidden="true" onClick={removeKeywordHandler}></i>
        </a>
    </label>
    );
};

export default SelectedKeywordItem;
