import React from 'react';
// import {useEffect, useState} from 'react';
import BarChartBody from './BarChartBody';

// import n_png from '../../assets/images/n.png'
// import d_png from '../../assets/images/d.png'
// import t_png from '../../assets/images/t.png'

import StoryKeywordList from '../../../shared/components/DefinedUIElements/StoryKeywordList';

const NewsBodyMain = props => {
    let arr1 = props.news.company;
    let arr2 = props.news.keywords;
    let kw_lst = arr1.concat(arr2);
    // console.log(props.news)
    let datePublished = new Date(props.news.dateModified)

    let sentiment = props.news.sentiment;
    let sentiment_div = <span className="plus-icon"><i className="fa fa-plus" aria-hidden="true"></i></span>

    if (sentiment > -0.05 && sentiment < 0.05){
        sentiment_div =
        <>
        <a href="javascript:void(0)">
          <span className="twoline-icon">
            <i className="fa fa-minus" aria-hidden="true"></i>
            <i className="fa fa-minus" aria-hidden="true"></i>
          </span>
        </a>
        <p>
          <label className="mb-0">Neutral</label> 
          {/* Possibility to make
          <br /> the market skeptical */}
        </p>
      </>
    }
    else if (sentiment >= 0.05){
        sentiment_div =
        <>
          <a href="javascript:void(0)">
            <span className="plus-icon">
              <i className="fa fa-plus" aria-hidden="true"></i>
            </span>
          </a>
          <p>
            <label className="mb-0">Positive</label> 
            {/* Possibility to make
            <br /> the market skeptical */}
          </p>
        </>

      }
    else{
        sentiment_div =
        <>
          <a href="javascript:void(0)">
            <span className="minus-icon-bg">
              <i className="fa fa-minus" aria-hidden="true"></i>
            </span>
          </a>
          <p>
            <label className="mb-0">Negative</label> 
            {/* Possibility to make
            <br /> the market skeptical */}
          </p>
        </>

    }
    // console.log(props.news)

    return (
        <div className="row">
        <div className="col-lg-7">
          <div className="d-flex align-items-center justify-content-between">
            <div className="">
              <h3 className="title">
                Smart Summary<sup>AI</sup>
              </h3>
              <p>
                All the facts, consisely told in 127 words{" "}
                {/* <label> */}
                  {/* <i className="fa fa-info-circle" aria-hidden="true"></i>{" "} */}
                  {/* <span className="popinfo"> */}
                    {/*props.news.summary*/}
                  {/* </span> */}
                {/* </label> */}
              </p>
              <p></p>
              <p><b>Published on : </b>
                <span>{" "+(datePublished.getDate()) +" / "+ (datePublished.getMonth()+ 1) +" / "+ datePublished.getFullYear()}
                 {" - " +datePublished.getHours() + " : "+ datePublished.getMinutes()+" "}  
                 </span>
              </p>
              <p> <b>Publisher : </b>
                <span>{props.news.publisher}</span>
              </p>
            </div>
            <a href="javascript:void(0)" className="pull-right">
              <img src="images/Subtract.png" alt="" title="" />
            </a>
          </div>
          <div className="summary">
          <p>{props.news.summary}</p>
          <p><a href={props.news.article_link} target="_blank">For Full Article Click Here</a></p>
            <label className="pt-3 mb-1">Overall Sentiment</label>
            <ul className="d-flex align-items-center">
              <li className="d-flex align-items-center">
                {sentiment_div}
              </li>
            </ul>

            <label className="">Keywords</label>
            <div className="skew-img ">
              <div className="rgt-sec">
                <StoryKeywordList kw_lst={kw_lst} more={true}/>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-5">
          <h3 className="title">
            Sentiment Analytics<sup>AI</sup>
          </h3>
          <p className="pb-2">
            Inspect how this story actually affected the market (and vice versa){" "}
            <label>
              <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
              <span className="popinfo">
                {/* {props.news.summary} */}
              </span>
            </label>
          </p>
          {/* <div className="img-fluid"> */}
          <BarChartBody ArticleId={props.news._id} chartName="stackedBarChart" height="400" width="370" />
              {/* <StackedBarChart sentiment={props.sentiment.sentiment} /> */}
            {/* <svg id="stacked"></svg> */}
          {/* </div> */}
        </div>
      </div>
    );
  };

export default NewsBodyMain;
