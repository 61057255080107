import React, { useEffect, useState } from "react";

import twitter_png from '../../assets/images/twitter.png'
import ArticleCard from "./ArticleCard";

const ArticleCardList = props => {
    return (
        <div className="row">
          {props.articles.map((article,i) => (
            <ArticleCard key={i} article={article} />
          ))}
        </div>
    );
};

export default ArticleCardList;
