import React, { useEffect, useState } from "react";

import twitter_png from '../../assets/images/twitter.png'

const ArticleCard = props => {

  let day = new Date(props.article.dateModified);


  return (
    <div className="col-lg-4 pl-2 pr-2">
        <div className="card">
        <div className="card-header">
            <div className="inner-header d-flex align-items-center justify-content-between">
            <ul className="d-flex align-items-center">
                {/* <li>
                <img src={twitter_png} align="twitter" title="" />
                </li> */}
                <li>
                {/* <img src="images/profile2.png" align="Profile" title="" /> */}
                <img className="img-thumbnail" src={props.article.logo} align="Profile" title="" />
                </li>
                <li>
                <span>
                    {/* <b>10:50 am</b> */}
                    <b>{day.getHours()}:{day.getMinutes()}</b>
                </span>
                {/* <span>20/12/2019</span> */}
                <span>{day.getDate()}/{day.getMonth() + 1}/{day.getFullYear()}</span>
                </li>
            </ul>
            </div>
            <p>
                <span>{props.article.publisher}</span> &gt; <span>{props.article.author}</span>
            </p>
        </div>
        <div className="card-body">
            <h4>
            <a href="javascript:void(0)">
                {props.article.title}
            </a>
            </h4>
        </div>
        </div>
    </div>
  );
};

export default ArticleCard;
