import React, {useState, useEffect, forwardRef, useRef, useImperativeHandle, useContext} from 'react';
import Collapse from 'react-bootstrap/Collapse';

import Tab from 'react-bootstrap/Tab'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import NavLink from 'react-bootstrap/NavLink'

import { AuthContext } from '../../../../shared/context/auth-context';
import { ApiMapsContext } from '../../../../shared/context/ApiMaps'

import SelectedKeywordList from '../Filter/SelectedKeywordList';


const FilterTabs = forwardRef((props, ref) => {
    const apiMaps = useContext(ApiMapsContext);
    const auth = useContext(AuthContext);
    const userId = auth.userId
    const access_token = auth.token;

    // const [open, setOpen] = useState(true);

    const [selectedKeywords, setSelectedKeywords] = useState(["company_name"]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);

    //  Add Keyword Method. TODO : rename this function wherever it is used.
    useImperativeHandle(ref, () => ({

        addKeywordHandler(type, addKeyword) {
            let shareData;
            if (type == "keywords"){
                setSelectedKeywords(selectedKeywords => [...selectedKeywords, addKeyword])
                shareData = {
                    "action_name":"insert_filter",
                    "keywords":[addKeyword]
                }
            }
            if (type == "categories"){
                setSelectedCategories(selectedCategories => [...selectedCategories, addKeyword])
                shareData = {
                    "action_name":"insert_filter",
                    "categories":[addKeyword]
                }
            }
            if (type == "companies"){
                setSelectedCompanies(selectedCompanies => [...selectedCompanies, addKeyword])
                shareData = {
                    "action_name":"insert_filter",
                    "companies":[addKeyword]
                }
            }

            //// sending user behavior data
            // shareData = {
            //     "action_name":"insert_filter",
            //     type:[addKeyword]
            // }
            // console.log("Insert Filter data :")
            // console.log(shareData)
            props.onUserBehavior(shareData)

        }
    }));

    // const addKeywordHandler = (type, addKeyword) => {
    //     console.log("add keywords")
    //     if (type == "keywords"){
    //         setSelectedKeywords(selectedKeywords => [...selectedKeywords, addKeyword])
    //     }
    //     if (type == "industries"){
    //         setSelectedIndustries(selectedIndustries => [...selectedIndustries, addKeyword])
    //     }
    //     if (type == "messages"){
    //         setSelectedMessages(selectedMessages => [...selectedMessages, addKeyword])
    //     }
    // }

    const removeKeywordHandler = (filter, removeKeyword) => {
        let shareData;

        if (filter == "keywords"){
            let temp = [...selectedKeywords];
            var index = temp.indexOf(removeKeyword);
            if (index !== -1) temp.splice(index, 1);
            setSelectedKeywords(temp)

            shareData = {
                "action_name":"delete_filter",
                "keywords":[removeKeyword]
            }
        }
        if (filter == "categories"){
            let temp = [...selectedCategories];
            var index = temp.indexOf(removeKeyword);
            if (index !== -1) temp.splice(index, 1);
            setSelectedCategories(temp)

            shareData = {
                "action_name":"delete_filter",
                "categories":[removeKeyword]
            }
        }
        if (filter == "companies"){
            let temp = [...selectedCompanies];
            var index = temp.indexOf(removeKeyword);
            if (index !== -1) temp.splice(index, 1);
            setSelectedCompanies(temp)

            shareData = {
                "action_name":"delete_filter",
                "companies":[removeKeyword]
            }
        }

        //// sending user behavior data
        // shareData = {
        //     "action_name":"delete_filter",
        //     type:[removeKeyword]
        // }
        // console.log("Delete Filter data :")
        // console.log(shareData)
        props.onUserBehavior(shareData)

    }


    useEffect(()=> {
        setSelectedKeywords(selectedKeywords => []);
        setSelectedCategories(selectedCategories => []);
        setSelectedCompanies(selectedCompanies => []);

    },[props.isReset]);

    
    // fetching older filters
    // keyword fetch
    useEffect(() => {
        fetch(
            `${apiMaps.selectedFilter}?type=keywords&access_token=${access_token}`
        )
            .then(res => res.json())
            .then(
            result => {
                // console.log(result)
                if(result.terms){
                    setSelectedKeywords(result.terms);
                }
            },
            error => {}
        );
    }, []);
    
    // fetching for companies
    useEffect(() => {
        fetch(
            `${apiMaps.selectedFilter}?type=companies&access_token=${access_token}`
        )
            .then(res => res.json())
            .then(
            result => {
                // console.log(result)
                if(result.terms){
                    setSelectedCompanies(result.terms);
                }
            },
            error => {}
        );
    }, []);

    // fetching for categories
    useEffect(() => {
        fetch(
            `${apiMaps.selectedFilter}?type=categories&access_token=${access_token}`
        )
            .then(res => res.json())
            .then(
            result => {
                // console.log(result)
                if(result.terms){
                    setSelectedCategories(result.terms);
                }
            },
            error => {}
        );
    }, []);
    

    return (
    <React.Fragment>
        <div className="col-lg-6">
            {/* <Tab.Container id="left-tabs-example" defaultActiveKey="keywords">  */}
            <div className="custom-tabs d-flex">
                <Nav className="nav nav-tabs d-flex" id="myTab" role="tablist">
                    <Nav.Item className="nav-item">
                        <NavLink eventKey="keywords" >Keywords</NavLink>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                        <NavLink eventKey="categories">Categories</NavLink>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                        <NavLink eventKey="companies">Companies</NavLink>
                    </Nav.Item>
                </Nav>
                <div className="tab-content">
                    <Tab.Pane eventKey="keywords">
                        <label>Selected Keywords:</label>
                        <SelectedKeywordList keywords={selectedKeywords} filter="keywords" onKeywordRemove={removeKeywordHandler} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="categories">
                        <label>Selected Categories:</label>
                        <SelectedKeywordList keywords={selectedCategories} filter="categories" onKeywordRemove={removeKeywordHandler} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="companies">
                        <label>Selected Companies:</label>
                        <SelectedKeywordList keywords={selectedCompanies} filter="companies" onKeywordRemove={removeKeywordHandler} />
                    </Tab.Pane>
                </div>
            </div>
            {/* </Tab.Container> */}
        </div>
    </React.Fragment>
    );
});

export default FilterTabs;
