import React, {useRef, useEffect, useState, useContext} from 'react';
import StackedBarChart from './StackedBarChart';
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'

import "./ChartBody.css"
import { ApiMapsContext } from '../../../shared/context/ApiMaps'

const BarChartBody = props => {
    const apiMaps = useContext(ApiMapsContext);
    const [loadedSentiment, setLoadedSentiment] = useState();

    const buttonNameRef = useRef();

    const chartActionHandler = (e) => {
        buttonNameRef.current.children[0].innerText = e.target.name;
        let temp_type = e.target.getAttribute("value")
        fetchData(`${apiMaps.sentimentGraph}?id=${props.ArticleId}&weeks=${temp_type}`);
    };

    const fetchData = (endpoint) => {
        fetch(endpoint,
        {
            method: "GET",
            dataType: "JSON",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            }
        })
        .then(res => res.json())
        .then(
            result => {
                setLoadedSentiment(result.sentiments);
            },
            error => {}
        );
    }

    useEffect(() => {
        fetchData(`${apiMaps.sentimentGraph}?id=${props.ArticleId}&weeks=1`);
      }, []);
    // }, [props]);

    useEffect(() => {
    }, [loadedSentiment]);


    return(
    <React.Fragment>
    {loadedSentiment && (
    <div className="img-fluid">
        <StackedBarChart sentiment={loadedSentiment} height={props.height} width={props.width} />

        {/* <StackedBarChart sentiment={loadedSentiment} height={props.height} width={props.width} /> */}
        {/* <StackedBarChart /> */}

        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <label className="">Resolution</label>
                </div>
                <div className="col-md-9">
                    <label>Tracing</label>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 chart-resolution">
                    <DropdownButton id="dropdown-basic-button" title="Options" ref={buttonNameRef}>
                        <>
                          <Dropdown.Item name="1 weeks" value="1" href="#/action-1" onClick={chartActionHandler} >1 week</Dropdown.Item>
                          <Dropdown.Item name="2 weeks" value="2" href="#/action-2" onClick={chartActionHandler} >2 weeks</Dropdown.Item>
                          <Dropdown.Item name="3 weeks" value="3" href="#/action-3" onClick={chartActionHandler} >3 weeks</Dropdown.Item>
                        </>
                    </DropdownButton>
                </div>
                <div className="col-md-9 chart-tracing">
                    <span>Sentiment Analysis Graph</span>
                </div>
            </div>
        </div>
    </div>
    )}
    </React.Fragment>
    );
}

export default BarChartBody;
