import React from 'react';
import { ResponsiveLine, Line } from '@nivo/line'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.


const NiftyChart = props => {
    let tempdata = [
        // {
        //   "id": "japan",
        //   "color": "hsl(286, 70%, 50%)",
        //   "data": [
        //     {x:"2020-05-07T11:50:01.276544", y:-2.6403418803418903},
        //     {x:"2020-05-07T12:00:01.244277", y:-2.705982905982906},
        //     {x:"2020-05-07T12:10:01.296191", y:-2.898119658119673},
        //     {x:"2020-05-07T12:20:01.006125", y:-2.8967521367521467},
        //     {x:"2020-05-07T12:30:01.182938", y:-3},
        //     {x:"2020-05-07T12:40:01.023466", y:-2.74017094017094},
        //     {x:"2020-05-07T12:50:01.144695", y:-2.6177777777777926},
        //     {x:"2020-05-07T13:00:01.523029", y:-2.86461538461539},
        //     {x:"2020-05-07T13:10:01.008591", y:-2.5733333333333483},
        //     {x:"2020-05-08T09:00:01.379655", y:-2.9781196581196783}
        //  ]
        // },
        // {
        //   "id": "france",
        //   "color": "hsl(84, 70%, 50%)",
        //   "data": [
        //     {x:"2020-05-08T09:50:01.109671", y:-1.1962393162393212},
        //     {x:"2020-05-08T10:00:01.001866", y:-1.5196581196581196},
        //     {x:"2020-05-08T10:10:01.013960", y:-1.2714529914529964},
        //     {x:"2020-05-08T10:20:01.018076", y:-1.2823931623931824},
        //     {x:"2020-05-08T10:30:01.093133", y:-1.3083760683760832},
        //     {x:"2020-05-08T10:40:01.036569", y:-1},
        //     {x:"2020-05-08T10:50:01.424246", y:-1.1921367521367672},
        //     {x:"2020-05-08T11:00:01.013084", y:-1.0882051282051382},
        //     {x:"2020-05-08T11:10:01.277738", y:-1.2105982905983106},
        //     {x:"2020-05-08T11:20:01.032250", y:-1.1141880341880392}
        // ]
        // },
        // {
        //   "id": "us",
        //   "color": "hsl(229, 70%, 50%)",
        //   "data": [
        //     {x:"2020-05-08T11:30:01.031489", y:-1.1476923076923227,},
        //     {x:"2020-05-08T11:40:01.070492", y:-1.14495726495727,},
        //     {x:"2020-05-08T11:50:01.069259", y:-1.075897435897451,},
        //     {x:"2020-05-08T12:00:01.100392", y:-1.0335042735042834,},
        //     {x:"2020-05-08T12:10:01.104260", y:-1.207179487179507,},
        //     {x:"2020-05-08T12:20:01.024855", y:-1.3165811965812164,},
        //     {x:"2020-05-08T12:30:01.397901", y:-1.421196581196596,},
        //     {x:"2020-05-08T12:40:01.073014", y:-1.3247863247863247,},
        //     {x:"2020-05-08T12:50:01.045359", y:-1.3494017094017243,},
        //     {x:"2020-05-08T13:00:01.151114", y:-1.28512820512821,}
        // ]
        // },
        {
          "id": "germany",
          "color": "hsl(150, 70%, 50%)",
          "data": [
            {x:"1", y:-1.084102564102584,},
            {x:"2", y:-1.110085470085485,},
            {x:"3", y:-1.3370940170940369,},
            {x:"4", y:-1.263931623931639,},
            {x:"5", y:-1.1736752136752235,},
            {x:"6", y:-1.6776068376068527,},
            {x:"7", y:-1.5162393162393162,},
            {x:"8", y:-1.9312820512820563,},
            {x:"9", y:-1.7452991452991453,},
            {x:"10", y:-1.8998290598290748,},
            {x:"11", y:-1.8998290598290748,},
            {x:"12", y:-1.8998290598290748,},
            {x:"13", y:-1.8998290598290748,},
            {x:"14", y:-1.8998290598290748,},
            {x:"15", y:-1.8998290598290748}
          ]
        },
        {
          "id": "norway",
          "color": "hsl(223, 70%, 50%)",
          "data": [
            {x:"11", y:-1.9825641025641074,},
            {x:"2", y:-2.197948717948723,},
            {x:"3", y:-2.258119658119658,},
            {x:"4", y:-2.2006837606837757,},
            {x:"5", y:-2.2594871794871842,},
            {x:"6", y: -2.260854700854711,},
            {x:"7", y:-2.260854700854711,},
            {x:"15", y:-2.260854700854711,},
            {x:"9", y:-2.260854700854711,},
            {x:"10", y:-2.260854700854711,},
          ]
        }
      ];

    const data = props.niftyData;

    return (
        <div style={{"height":props.height,"width":props.width}}>

            <ResponsiveLine
                data={data}
                margin={{ top: 10, right: 50, bottom: 20, left: 60 }}
                xScale={{ type: 'point' }}
                // xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                curve="monotoneX"
                // xScale={{ type: "point" }}
                // yScale={{
                //   type: "time",
                //   format: "%H:%M",
                //   precision: "minute"
                // }}
                enableGridX={false}
                yFormat="time:%Hh %Mm"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 10,
                    tickRotation: 270,
                    legend: 'Time',
                    legendOffset: 36,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Sentiments',
                    legendOffset: -40,
                    legendPosition: 'middle'
                }}
                colors={{ scheme: 'dark2' }}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabel="xFormatted"
                enablePointLabel={false}
                pointLabelYOffset={0}
                useMesh={true}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
            />
   
   {/* <Line
        width="600"
        height= "400"
        
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}

        data={[
            // {
                
            //     id: 'fake corp. A',
            //     data: [
            //         { x: '2018-01-01', y: 7 },
            //         { x: '2018-01-02', y: 5 },
            //         { x: '2018-01-03', y: 11 },
            //         { x: '2018-01-04', y: 9 },
            //         { x: '2018-01-05', y: 12 },
            //         { x: '2018-01-06', y: 16 },
            //         { x: '2018-01-07', y: 13 },
            //         { x: '2018-01-08', y: 13 },
            //     ],
            // },
            // {
            //     id: 'fake corp. B',
            //     data: [
            //         { x: '2018-01-04', y: 14 },
            //         { x: '2018-01-05', y: 14 },
            //         { x: '2018-01-06', y: 15 },
            //         { x: '2018-01-07', y: 11 },
            //         { x: '2018-01-08', y: 10 },
            //         { x: '2018-01-09', y: 12 },
            //         { x: '2018-01-10', y: 9 },
            //         { x: '2018-01-11', y: 7 },
            //     ],
            // },
            {
                id: "hours",
                data: [
                  { x: "A", y: "04:00" },
                  { x: "B", y: "02:00" },
                  { x: "C", y: "07:00" },
                  { x: "D", y: "04:00" }
                ]
              }
            
        ]}
        xScale={{
            type: 'time',
            // format: '%Y-%m-%d',
            format: '%H:%M',
            useUTC: false,
            precision: 'day',
        }}
        // xFormat="time:%Y-%m-%d"
        xFormat="time:%H:%M"
        yScale={{
            type: 'linear',
            // stacked: boolean('stacked', false),
        }}
        axisLeft={{
            legend: 'linear scale',
            legendOffset: 12,
        }}
        axisBottom={{
            // format: '%b %d',
            format: "%Hh%M",
            tickValues: 'every 2 days',
            legend: 'time scale',
            legendOffset: -12,
        }}
        // curve={select('curve', curveOptions, 'monotoneX')}
        enablePointLabel={true}
        // pointSymbol={CustomSymbol}
        pointSize={16}
        pointBorderWidth={1}
        pointBorderColor={{
            from: 'color',
            modifiers: [['darker', 0.3]],
        }}
        useMesh={true}
        enableSlices={false}
    /> */}
            </div>
        )
}

export default NiftyChart
