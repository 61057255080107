import React, { useEffect, useState, useContext } from "react";
import ArticleCard from "./ArticleCard";
import ArticleCardList from "./ArticleCardList";
import ArticlesByDate from "./ArticlesByDate";

import { ApiMapsContext } from '../../../shared/context/ApiMaps'

const NewsBodyRelatedArticle = props => {
  const apiMaps = useContext(ApiMapsContext);

  const structureArticles = (articles) => {
    let dct = {}
    for(let i=0;i<articles.length;i++){
        if(articles[i]){
            let article_date = new Date(articles[i].dateModified);
            article_date = article_date.toJSON().slice(0,10).replace(/-/g,'/');

            if (!dct[article_date]){
                dct[article_date] = [articles[i]]
            }
            else {
                dct[article_date].push(articles[i]);
            }
        }
    }
    return dct
  }

  // const [prevDate, setPrevDate] = useState(1);
  const [newsPage, setNewsPage] = useState(1);
  const [loadedNews, setloadedNews] = useState([]);

  const [structuredLoadedNews, setStructuredLoadedNews] = useState([]);

  const fetchData = (type) => {
    // console.log(`${apiMaps.relatedArticles}?id=${props.ArticleId}&weeks=4&index=${type}`)
    fetch(`${apiMaps.relatedArticles}?id=${props.ArticleId}&weeks=4&index=${type}`,
    {
        method: "GET",
        dataType: "JSON",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        }
    })
    .then(res => res.json())
    .then(
        result => {
          if (result){
            setNewsPage(newsPage +1)

            let articles = result.articles;
            let structured_articles = structureArticles([...loadedNews, ...articles]);
            // return result.articles;
            setloadedNews(loadedNews => [...loadedNews, ...result.articles] );

            setStructuredLoadedNews(structuredLoadedNews => structured_articles);
          }
        },
        error => {}
    );
  }

  const loadMoreHandler = () => {
      // console.log("more clicked")
      // setNewsPage(newsPage => newsPage+1)
      fetchData(newsPage);
  }

  useEffect(() => {
    // console.log("fetch one")
      fetchData(1);
  }, [props]);

  // useEffect(() => {
  // }, [structureArticles]);


  return (
    <div className="articles feedsense-body pt-3">
      <label className="mb-0">Prominent Articles & Tweets</label>
      {Object.keys(structuredLoadedNews).map((obj, i)=> (
        // console.log(structuredLoadedNews[obj]+ obj)
        <ArticlesByDate key={i} ddate={new Date(obj)} articles={structuredLoadedNews[obj]} />
      ))}

      <button className="common-btn" onClick={loadMoreHandler}>Load More</button>

    </div>

  );
};

export default NewsBodyRelatedArticle;
