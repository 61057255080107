import React from 'react';
import {Dropdown} from 'react-bootstrap';

import './StoryKeywordMoreItems.css'

const StoryKeywordMoreItems = props => {
    const kw_lst = props.keywords;
    return (
    <Dropdown>
        <Dropdown.Toggle variant="transperent btn-xs" id="dropdown-basic">
            ...
        </Dropdown.Toggle>

        <Dropdown.Menu>
            {kw_lst.map( (name,i) => (
                <Dropdown.Item key={i} href="#" >{name}</Dropdown.Item>
            ))}

            {/* <Dropdown.Item href="#/action-2" disabled >Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3" disabled >Something else</Dropdown.Item> */}
        </Dropdown.Menu>
    </Dropdown>    )
}

export default StoryKeywordMoreItems;
